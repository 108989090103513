$msv-invoice-summary-font: $msv-primary-font-family;
$msv-invoice-summary-padding: 32px;
$msv-invoice-summary-heading-padding-bottom: 7px;
$msv-invoice-summary-items-border-radius: 2px;
$msv-invoice-summary-label-line-height: 20px;
$msv-invoice-summary-line-padding: 5px 0;

//style presets
:root {
	--msv-invoice-summary-bg: var(--msv-bg-color);
	--msv-invoice-summary-font-color: var(--msv-font-primary-color);
	--msv-invoice-summary-font-size: var(--msv-body-font-size-l);
	--msv-invoice-summary-border: var(--msv-font-primary-color);

	// heading
	--msv-invoice-summary-heading-font-color: var(--msv-font-primary-color);
	--msv-invoice-summary-heading-font-size: var(--msv-body-font-size-xl);
}

.msc-invoice-summary {
	&-wrapper {
		align-items: center;
		background-color: var(--msv-invoice-summary-bg);
		display: flex;
		flex: 0 0 368px;
		flex-direction: column;
		margin: 0 auto;
		padding: $msv-invoice-summary-padding;
		height: fit-content;
	}

	@media screen and (max-width: $msv-breakpoint-s) {
		margin: unset;
	}

	&__heading {
		@include font-content(var(--msv-font-weight-heavy), var(--msv-invoice-summary-heading-font-size), $msv-line-height-xl);
		color: var(--msv-invoice-summary-heading-font-color);
		font-style: $msv-invoice-summary-font;
		padding-bottom: $msv-invoice-summary-heading-padding-bottom;
		width: 100%;
	}

	&__items {
		color: var(--msv-invoice-summary-font-color);
		border-radius: $msv-invoice-summary-items-border-radius;
		border-top: 1px solid var(--msv-invoice-summary-border);

		@include font-content(var(--msv-font-weight-normal), var(--msv-invoice-summary-font-size), $msv-line-height-l);
		width: 100%;

		.msc-promo-code {
			&__group {
				display: flex;
				padding-top: 10px;
			}

			&__input-box {
				flex: auto;
				margin-right: 20px;
				padding-left: 8px;
			}
		}
	}

	&__line {
		&-net-price,
		&-invoice,
		&-loyalty,
		&-gift-card,
		&-total-discounts {
			padding: 10px 0 10px;
		}

		&-total {
			@include font-content(var(--msv-font-weight-normal), var(--msv-invoice-summary-font-size), $msv-line-height-l);
			padding: 10px 0 20px;
			border-top: 1px solid var(--msv-invoice-summary-border);
			text-transform: uppercase;
		}
	}

	&__value {
		float: right;
		text-align: right;
		font-weight: var(--msv-font-weight-bold);
	}
}

@media screen and (min-width: $msv-breakpoint-l) {
	.msc-invoice-summary-wrapper {
		margin: 0;
	}
}
