.msc-modal {
    .msc-add-to-order-template-dialog {
        &__empty {
            &__dialog {
                &__content {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    @media screen and (min-width: $msv-breakpoint-m) {
                        padding: 100px 0;
                    }
                }

                &__create-order-template-image-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }

                &__create-order-template-image {
                    font-size: 100px;
                    color: #BFBFBF;
                    pointer-events: none;
                }

                &__no-order-templates {
                    font-size: 24px;
                    line-height: 31px;
                    padding: 28px;
                    text-align: center;
                }

                &__no-order-templates-description {
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 21px;
                    text-align: center;
                }

                &__create-order-template-button {
                    @include primary-button();
                }

                &__cancel-button {
                    @include secondary-button();
                    margin: 0 20px;
                }

                @media screen and (max-width: $msv-breakpoint-m) {
                    &__footer {
                        button {
                            width: 100%;
                            margin-left: 0;
                            margin-right: 0;
                            margin-bottom: 12px;
                        }
                    }
                }
            }
        }

        &__existing {
            max-width: 1130px;

            &__dialog {
                &__order-templates-description {
                    font-size: 16px;
                    line-height: 18px;
                    margin: 10px 0;
                }

                &__order-templates-container {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    @media screen and (max-width: 793px) {
                        justify-content: center;
                    }
                }

                &__order-templates-line {
                    &__item_header {
                        padding: 8px 0;
                    }

                    &__container {
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        background: #FFFFFF;

                        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
                        border-radius: 3px;
                        margin: 0 10px 40px 10px;

                        font-size: 16px;
                        line-height: 21px;
                        height: 426px;
                        min-width: 316px;
                        width: 316px;

                        &__create-new-line {
                            cursor: pointer;

                            &__image {
                                font-size: 100px;
                                color: #BFBFBF;
                                margin-top: 150px;
                                width: 100%;
                                text-align: center;
                            }

                            &__text {
                                font-size: 18px;
                                line-height: 24px;
                                color: $msv-primary;
                                width: 100%;
                                text-align: center;
                                margin-top: 24px;
                            }
                        }
                    }

                    &__title {
                        font-weight: var(--msv-font-weight-bold);
                        padding-left: 12px;
                        padding-right: 12px;
                    }

                    &__count {
                        padding-left: 12px;
                        padding-right: 12px;
                        padding-top: 4px;
                    }

                    &__products {
                        &__image-container {
                            width: 292px;
                            height: 292px;
                            margin-left: 12px;
                            margin-right: 12px;
                            background: #F9F9F9;
                        }

                        &__image-props{
                            padding-left: 4px;
                            padding-right: 4px;
                            padding-top: 4px;
                            max-width: 140px;
                            margin-right: 2px;
                            margin-left: 2px;
                        }
                    }

                    &__action-button {
                        @include secondary-button();
                        margin: 12px;
                    }
                }
            }
        }
    }
}