$fa-font-path: '../../../webfonts' !default;
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/brands';
@import '../00-settings/colors';

$brandPrimaryColor: $msv-white;
$brandSecondaryColor: #cccccc;
$backgrounDefaultColor: #f8f7f6;
$textDefaultColor: #333333;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: $msv-black;
$expandedCategoryColor: $msv-black;
$link-color: $msv-black;
$link-decoration: none;
$thumbnail-height: 35px;
$thumbnail-width: 45px;

:root {
	--brandPrimaryColor: #{brandPrimaryColor};
	--brandSecondaryColor: #{brandSecondaryColor};
	--backgroundDefaultColor: #{$backgrounDefaultColor};
	--textDefaultColor: #{textDefaultColor};
	--bodyFont: #{bodyFont};
	--displayFont: #{displayFont};
	--borderRadius: #{borderRadius};
	--iconStyle: #{iconStyle};
	--categoryColor: #{categoryColor};
	--expandedCategoryColor: #{expandedCategoryColor};
	--accentColor: #{accentColor};
	--link-color: #{link-color};
	--link-decoration: #{link-decoration};
}

body {
	background-color: var(--backgroundDefaultColor);
	max-width: 100%;
	overflow-x: hidden;
}

a {
	// color: $link-color !important;
	text-decoration: $link-decoration;
	background-color: transparent;

	@include hover() {
		// color: $link-color;
		text-decoration: $link-decoration;
	}
}

label {
	margin: 0px;
}

.ms-search-result-container__refiner-section {
	.ext {
		width: '100%';
	}
}

.customize-ingredients-box {
	width: 100%;
	.ingredients-box-header {
		justify-content: space-between;
		align-items: center;
	}

	.ingredients-item-container {
		display: flex;
		flex-flow: wrap;
		flex: 1;
		background-color: #f3f3f3;
		.ms-refine-submenu-item {
			line-height: normal;
			width: 35px;
			margin: 0px 10px;
			&.multi-select {
				&:before {
					font-size: 35px;
				}
			}
			&.multi-select-checked {
				&:before {
					font-size: 35px;
					color: $accentColor;
				}
			}
		}

		.ingredient-item-sub-checked {
			.ms-refine-submenu-item {
				&.multi-select {
					&:before {
						font-size: 35px;
					}
				}
				&.multi-select-checked {
					&:before {
						font-size: 35px;
						color: $accentColor;
					}
				}
			}
		}
	}
}

.ingredient-item {
	box-sizing: border-box;
	padding: 5px;
	.ingredient-item-line {
		background-color: $brandPrimaryColor;
	}
	.ingredient-item-line-selected {
		background-color: $brandPrimaryColor;
	}
}
// .ms-refine-submenu-item{
//   line-height: normal;
//   width: 35px;
//   margin: 0px 10px;
// }

.ms-refine-submenu-item-row {
	display: inline-table;
	padding: 5px;

	.increment {
		margin: 0px 10px;
	}

	.ingredients-edit-label {
		display: inline;
		vertical-align: -webkit-baseline-middle;
	}

	.ingredient-checkbox-label {
		vertical-align: -webkit-baseline-middle;
	}

	.decrement {
		margin: 0px 10px;
	}
}

.substitute-box {
	display: flex;
	flex-direction: column;
	border: 1px solid $accentColor;
	box-sizing: border-box;
	background-color: $backgrounDefaultColor;
	position: absolute;
	z-index: 1000;
	top: 100%;
	left: 0;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.132);

	.header {
		display: flex;
		flex-direction: column;
		padding: 12px 12px 0px 12px;

		.close {
			margin-left: auto;
			cursor: pointer;
		}

		.info {
			margin-top: 8px;
			margin-bottom: 8px;
			font-weight: normal;
			font-size: 14px;
			line-height: 18px;
			text-transform: none;
		}
	}

	.substitute-header-container {
		display: flex;
		width: 100%;
		flex: 1 1 auto;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;

		.substitute-header {
			word-break: break-all;
			margin: 0px;
		}

		.substitute-item {
			cursor: pointer;
			box-sizing: border-box;
			width: inherit;
			padding: 17px 0px;
			border: none;
			margin: 5px 0px;
			text-align: center;
			word-break: break-all;
			background-color: $msv-secondary-hover;
			border: 1px solid $accentColor;
		}
	}

	.substitute-container {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		padding: 0px 12px 12px 12px;
		cursor: pointer;

		.substitute-item {
			cursor: pointer;
			box-sizing: border-box;
			width: inherit;
			padding: 17px 0px;
			border: none;
			margin: 5px 0px;
			text-align: center;
			background-color: #fff;
			word-break: break-all;
		}
	}
}

.modifiers-side {
	background-color: $brandSecondaryColor;
}

.modifiers-side-selected {
	background-color: $accentColor;
}

.modifiers-border {
	box-shadow: 0 0 5px 5px $accentColorShadow;
}

.modifiersSide {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	cursor: pointer;
	margin-right: 15px;
	margin-left: 15px;
}

.modifiersSideLeft {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 2px solid $brandSecondaryColor;
	overflow: hidden;
	display: flex;
	cursor: pointer;
}

.modifiersSideBackgroundWhite {
	background: white;
	height: 50px;
	width: 25px;
}

.modifiersSideBackground {
	height: 50px;
	width: 25px;
}

.modifiersSideRight {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 2px solid $brandSecondaryColor;
	overflow: hidden;
	display: flex;
	cursor: pointer;
}

.modifiersSideMiddle {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	cursor: pointer;
}

.modifiersSideimg {
	border-radius: 50%;
}

.customizeButtonModifiers {
	height: 100px;
	display: flex;
}

.modification_heading {
	//margin-left:25px;
	margin-bottom: 5px;
}

.ms-buybox {
	flex-flow: column;

	&__content {
		margin-right: 15px;
	}

	&__customize-ingredients-heading {
		margin-top: 20px;
		margin-bottom: 20px;
		width: auto;
	}

	&__media-gallery {
		flex-grow: 0;
	}

	&__modifier-container {
		width: 100%;
	}
}

.msbuybox-container {
	margin-left: 0px;
	margin-right: 0px;
}

.ingredient-checkbox-label {
	padding-left: 5px;
	padding-top: 5px;
	vertical-align: top;
}

.msbuybox-container-wrapper {
	position: relative;
}

.msbuyboxcenter {
	width: 100%;
	padding: 15px;
	.ms-buybox__quantity {
		margin-right: 20px;
	}
	.msbuybox-quantitybag {
		// display:inline-block;
		// min-width: 200px;
		// width: 80%;
		flex: 1 1 auto;
		height: 48px;
		margin-right: 20px;
	}
	// .msbuybox-quantitybag-wishlist {
	//   // display:inline-block;
	//   // width: 10%;
	//   // min-width: 75px;
	// }

	.msc-add-to-cart {
		width: 100%;
		height: 100%;
		border-radius: 2px;
	}

	.msc-add-to-wishlist {
		width: 48px;
		height: 48px;
		background: $msv-white;
		border: 1px solid $msv-primary;
		border-color: $msv-white;
		color: $msv-primary;
		font-size: 24px;
	}
}

.product-add-to-cart {
	padding-bottom: 15px;
}

.ingredientcontrol {
	border: none;
	border-radius: 2px;
	width: 35px;
	height: 35px;
	outline: none;
	cursor: pointer;

	@include applyAccentColors();
}

.msc-special-instruct__input-box {
	&:focus {
		border: 1px dashed #fff;
		outline: 1px dashed #000;
	}
}

.msc-special-instruct__input-box {
	flex: auto;
	padding-left: 8px;
	height: 50px;
	border: 1px solid black;
	border-color: transparent;
	background: #f8f7f6;
	outline: 1px dashed transparent;
	@include font-content-l();
}

.msc-order-summary-wrapper .msc-special-instruct__group {
	display: flex;
	width: 100%;
}

.msc-cart-line__product-image img {
	width: 80px;
	height: 80px;
}

.ms-buybox__quantity .quantity .decrement::after {
	content: none;
}

.ms-buybox__quantity .quantity .increment::after {
	content: none;
}

.msc-cart-line__product-quantity .quantity .decrement::after {
	content: none;
}

.msc-cart-line__product-quantity .quantity .increment::after {
	content: none;
}

.ms-buybox__selection-text-init-cap {
	text-transform: capitalize;
}

.pclist {
	overflow: inherit;
	.msc-product {
		overflow: inherit;
	}
	.msc-product__image {
		.img-btn {
			border-radius: 2px;
			cursor: pointer;
			&:focus {
				outline: none;
			}
		}

		.img-btn-top-left {
			position: absolute;
			top: 0%;
			left: 0%;
			border: none;
			background: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/ttdpxtwlrx/imageFileData/MAQgw');
			width: 48px;
			height: 48px;
			padding: 2px;
			background-size: cover;
			margin-left: 5px;
			margin-top: 5px;
		}

		.img-btn-top-right {
			position: absolute;
			top: 0%;
			right: 0%;
			border: none;
			background: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/ttdpxtwlrx/imageFileData/MAQgz');
			width: 48px;
			height: 48px;
			padding: 2px;
			background-size: cover;
			margin-top: 5px;
			margin-right: 5px;
		}

		.img-btn-bottom-left {
			position: absolute;
			bottom: 0%;
			left: 0%;
		}

		.img-btn-bottom-right {
			position: absolute;
			bottom: 0%;
			right: 0%;
			background-color: #ffffff;

			&:hover {
				background-color: $msv-secondary-hover;
			}
		}
	}
	.msc-add-to-cart {
		margin: 10px;
		min-width: 120px;
		padding: 5px 10px;
	}
}

.fx-icon {
	font-size: 35px;
}

.addBag {
	border-color: black;
	margin: 5px;
	background-color: black;
}

.cust {
	border-color: black;
	margin: 5px;
	background-color: black;
}

.lrwFix {
	min-height: 25px;
	//padding-left:25px;
}

.ms-media-gallery {
	padding-right: 40px;
}

.margintop50 {
	margin-top: 50px;
}

.center-align {
	text-align: center;
}

.accentBg {
	background-color: $accentColor;
}

.pc-accent-circle {
	color: $accentColor;
	background: white;
	border-radius: 50%;
	right: 1px;
	top: 1px;
	position: absolute;
}

.pc-red-asterick {
	color: $msv-white;
	background: red;
	border-radius: 50%;
	right: 1px;
	top: 1px;
	position: absolute;
}

.heading-pc-border {
	border: solid 2px $brandSecondaryColor;
	display: inline-block;
	padding: 5px;
}

.heading-pc-border-alert {
	border: solid 2px red;
	display: inline-block;
	padding: 5px;
}

.pc-next-button {
	color: $msv-white;
	background-color: $accentColor;
	font-weight: normal;
	font-size: 12px;
	line-height: 24px;
	text-transform: none;
	cursor: pointer;
	height: 28px;
	width: 125px;
	text-align: center;
	display: inline-block;
	border: 1px solid transparent;
	border-radius: 2px;
	text-decoration: none;
	text-align: center;
	min-width: 25px;
	margin: 5px;

	&:disabled,
	&[disabled] {
		cursor: default;
		border-color: $msv-gray-300;
		background-color: $msv-gray-300;
		color: $msv-white;
	}
}

.accent {
	background-color: $accentColor;
	color: $accentColorText;
}

.ms-buybox-ext__lifestyle-delivery-mode-selector {
	width: auto;
}

.lifestyle-delivery-mode-selector {
	width: auto;
}

.lifestyle-address-box {
	max-width: 700px;
}

.popup {
	position: relative;
	display: flex;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* The actual popup */
.popup .popuptext {
	visibility: hidden;
	width: 100%;
	min-height: 100%;
	background-color: $brandPrimaryColor;
	color: $categoryColor;
	text-align: center;
	border-radius: 6px;
	//padding: 8px 0;
	position: absolute;
	z-index: 1;
	top: 50px;
}

.popup .popupimage {
	visibility: hidden;
	width: 100%;
	min-height: auto;
	background-color: transparent;
	border-radius: 6px;
	//padding: 8px 0;
	position: absolute;
	z-index: 1;
	top: 50px;
	//right:50px;
}

/* Popup arrow */
.popup .popuptext::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
	visibility: visible;
	-webkit-animation: fadeIn 1s;
	animation: fadeIn 1s;
}

/* Modifiers */
.as-modifiers-image {
	height: $thumbnail-height;
	width: $thumbnail-width;
	padding: 0px;
}

.item-line {
	display: flex;
	flex-direction: column;

	.item-row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 0px;
		min-height: $thumbnail-height;

		.item-name {
			flex: 1 1 auto;
			word-break: break-all;
			padding-left: 5px;
		}

		.item-label {
			flex: 1 1 auto;
		}
	}
}

.cart-line_update {
	margin-left: 25px;
}

.as-modifiers-item-container {
	display: flex;
}

.msbuyboxcenter-container {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
}

.add-to-bag-container {
	display: flex;
	flex-wrap: wrap;
	flex: 1 1 80%;
	justify-content: flex-end;
	align-items: flex-end;
}

/* Product Configurators */
.pc-item {
	box-sizing: border-box;
	padding: 5px;
	.pc-item-line {
		background-color: $brandPrimaryColor;
		.ms-refine-configuration-item-row {
			position: relative;
			.pc-row-text-wrap {
				position: absolute;
				top: 0%;
				left: 0%;
				width: 100%;
				background-color: rgba(255, 255, 255, 0.5);
				height: 20px;
			}
			.pc-row-text {
				position: absolute;
				top: 0%;
				left: 0%;
				width: 88%;
				font-size: small;
				text-align: center;
				overflow: auto;
			}
			.pc-row-icon {
			}
			.pc-row-text-wrap-selection {
				position: absolute;
				bottom: 5%;
				left: 10%;
				width: 80%;
				background-color: rgba(0, 0, 0, 0.733);
				color: white;
				font-size: x-small;
				text-align: center;
				border-radius: 16.5px;
			}
		}
	}
	.pc-item-line-selected {
		background-color: rgba(255, 255, 255, 0.5);
	}
}

.ms-refine-configuration-item-row {
	width: 100px;
	height: 100px;

	&.single-select-checked {
		border: 2px solid $accentColor;
	}
	&:hover {
		border: 2px solid $accentColor;
	}
	&.selection-attr {
		border: 2px solid #c5786e;
	}
}

.ms-buybox__customize-ingredients-heading-pc {
	margin: 5px;
	width: 60%;
	font-weight: 500;
	letter-spacing: 0.5px;
	-moz-osx-font-smoothing: grayscale;
}

@media (min-width: 576px) {
	.item-line {
		.item-row {
			flex-wrap: nowrap;
		}
	}
}

@media (min-width: 1024px) {
	.item-line {
		flex-direction: row;
		.item-row {
			flex: 1 1 50%;
		}
	}
}

.hidden {
	display: none;
}

.mx-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
