$msc-order-details-cartline-product-image-margin-left: 20px;
$msc-order-details-sales-line-btn-buy-it-again-right: 100px;
$msc-order-details-order-information-padding: 8px;
$msv-details-sale-line-buy-again-btn-right: 167px;

[dir='rtl'] {
	.ms-order-details {
		&__sales-line-btn-buy-it-again {
			left: unset;
			right: $msv-details-sale-line-buy-again-btn-right;
		}

		&__order-information-sales-id {
			border-right: unset;
			padding-right: unset;
			padding-left: $msc-order-details-order-information-padding;
		}

		&__order-information-receipt-id {
			border-right: unset;
			padding-right: unset;
			padding-left: $msc-order-details-order-information-padding;
		}

		&__order-infomation-count {
			border-left: 1px solid $msv-gray-900;
		}

		&__order-information-amount {
			padding-left: unset;
			padding-right: $msc-order-details-order-information-padding;
			border-right: 1px solid $msv-gray-900;
		}

		&__group-delivery {
			padding-right: unset;
			padding-left: 20px;
		}

		.msc-cart-line {
			width: 100%;

			&__product-price {
				left: 0;
				text-align: left;
			}

			&__product-image {
				margin-left: $msc-order-details-cartline-product-image-margin-left;
				margin-right: 0;
			}
		}

		@media screen and (min-width: $msv-breakpoint-l) {
			&__order-summary,
			&__sales-lines {
				padding-left: 25%;
				padding-right: unset;
			}
		}

		@media screen and (max-width: $msv-breakpoint-m) {
			&__sales-line-btn-buy-it-again {
				left: unset;
				margin-left: unset;
				right: 0;
				margin-right: $msc-order-details-sales-line-btn-buy-it-again-right;
			}

			&__help {
				padding-right: 20px;
			}
		}

		&__sales-status {
			float: left;
		}

		&__order-summary {
			padding-right: 0;

			&-price {
				float: left;
			}
		}
	}
}
