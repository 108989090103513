#subheader .lifestyle-delivery-modes .mx-auto {
    display: flex;
    justify-content: center;

    .lifestyle-delivery-button, .lifestyle-pickup-button {
        margin: 10px;
    }
}

#subheader .lifestyle-delivery-modes .mx-auto.ms-checkout-shipping-address {
    display: block;
}

#subheader .lifestyle-delivery-modes .deliveryPickupAddress {
    display: flex;
    justify-content: center;
}