$popover-menu-item-height: 48px;

//style presets
:root {
    // text
    --msv-account-balance-text-font-size: var(--msv-body-font-size-m);
    --msv-account-balance-text-font-weight: var(--msv-font-weight-light);

    // bolder text
    --msv-account-balance-bolder-text-font-weight: var(--msv-font-weight-bold);

    // title
    --msv-account-balance-title-font-size: var(--msv-body-font-size-xl);
    --msv-account-balance-title-font-weight: var(--msv-font-weight-light);

    // account details section title
    --msv-account-balance-details-section-title-font-size: var(--msv-body-font-size-xl);
    --msv-account-balance-details-section-title-font-weight: var(--msv-font-weight-light);

    // customer account number text
    --msv-account-balance-customer-account-number-text-font-size: #{$msv-heading-text-size};

    // credit amount text
    --msv-account-balance-credit-amount-text-font-size: var(--msv-body-font-size-xxl);
}

.ms-account {
    &-customer-balance {
        max-width: 640px;
        font-weight: var(--msv-account-balance-text-font-weight);
        font-size: var(--msv-account-balance-text-font-size);

        h2 {
            padding: 25px 0;
            border-bottom: 1px solid #616365;
            font-weight: var(--msv-account-balance-title-font-weight);
            font-size: var(--msv-account-balance-title-font-size);
            line-height: normal;
        }

        h3 {
            padding-top: 30px;
        }

        .ms-account-customer-balance__credit-summary-heading {
            @include add-icon($msv-ListAlt, before);

            &::before {
                padding-right: 5px;
            }
        }

        &__account__credit,
        &__credit-summary__values {
            > div {
                font-weight: var(--msv-account-balance-bolder-text-font-weight);
                text-align: right;
            }
        }

        &__account,
        &__credit-summary {
            display: flex;
            padding: 1rem 0;
            justify-content: space-between;

            div {
                width: 100%;
                padding: 0.5rem 0;
            }
        }

        &__summary-details {
            flex-direction: column;

            .ms-account-customer-balance__credit-summary__wrapper {
                display: flex;

                & > div {
                    padding: 0;
                }
            }
        }

        &__account {
            border-bottom: 1px dashed #616365;

            &__details {
                h4 {
                    padding: 0.5rem 0;
                    font-weight: var(--msv-account-balance-details-section-title-font-weight);
                    font-size: var(--msv-account-balance-details-section-title-font-size);
                }

                .ms-account-customer-balance__account-number {
                    font-size: var(--msv-account-balance-customer-account-number-text-font-size);
                }
            }
        }

        &__credit-summary__values {
            .ms-account-customer-balance__excess-credit-label {
                &::before {
                    content: '(';
                }

                &::after {
                    content: ')';
                }
            }
        }

        &__account__credit {
            .ms-account-customer-balance__available-credit-label {
                font-weight: var(--msv-account-balance-text-font-weight);
                font-size: var(--msv-account-balance-customer-account-number-text-font-size);
            }

            .ms-account-customer-balance__available-credit-amount {
                font-weight: var(--msv-account-balance-text-font-weight);
                font-size: var(--msv-account-balance-credit-amount-text-font-size);
                line-height: 3rem;
            }
        }

        &__action {
            &__bar {
                margin-top: 10px;
            }

            &__requestStatement {
                border: 1px solid var(--msv-accent-brand-color);
                background-color: var(--msv-bg-color);
                padding: 10px;
                width: 100%;
                text-align: center;

                &__toggleDown {
                    @include add-icon($msv-ChevronDown, after);
                    border: 1px solid var(--msv-accent-brand-color);
                    background-color: var(--msv-bg-color);
                    text-align: center;
                    display: inline;
                    min-width: 160px;
                    margin-left: 20px;
                    margin-top: 20px;
                    margin-right: 20px;
                    height: fit-content;
                }

                &__toggleUp {
                    @include add-icon($msv-ChevronUp, after);
                    border: 1px solid var(--msv-accent-brand-color);
                    background-color: var(--msv-bg-color);
                    text-align: center;
                    display: inline;
                    min-width: 160px;
                    margin-left: 20px;
                    margin-top: 20px;
                    margin-right: 20px;
                    height: fit-content;
                }

                &__dropdownHidden {
                    visibility: hidden;
                    height: 0;
                    display: none;
                }

                &__dropdownVisible {
                    background-color: var(--msv-header-bg);
                    background-clip: padding-box;
                    border: 1px solid $msv-gray-500;
                    display: block;
                    left: 0;
                    position: absolute;
                    top: 0;
                    word-wrap: break-word;
                    z-index: 1060;

                    .msc-btn {
                        color: var(--msv-header-font-color);
                        font-weight: var(--msv-font-weight-normal);
                        height: $popover-menu-item-height;
                    }

                    .msc-arrow {
                        display: block;
                        height: 0.5rem;
                        margin: 0 0.3rem;
                        position: absolute;
                        top: calc((0.5rem + 1px) * -1);
                        width: 1rem;

                        &::before,
                        &::after {
                            border-color: transparent;
                            border-style: solid;
                            border-width: 0 0.5rem 0.5rem 0.5rem;
                            content: '';
                            display: block;
                            position: absolute;
                        }

                        &::before {
                            border-bottom-color: $msv-gray-500;
                            top: 0;
                        }

                        &::after {
                            border-bottom-color: var(--msv-border-color);
                            top: 1px;
                        }
                    }
                }

                &__dropdownOption {
                    background-color: var(--msv-bg-color);
                    display: block;
                    width: 100%;
                }

                &__buttonLabel {
                    padding-right: 20px;
                }
            }

            &__submitRequest {
                background-color: var(--msv-accent-brand-color);
                color: var(--msv-font-secondary-color);
                border: 1px solid var(--msv-font-secondary-color);
                padding: 10px;
                width: 25%;
                font-size: $msv-font-size-l;
                line-height: $msv-line-height-l;
            }

            &__cancelRequest {
                background-color: var(--msv-font-secondary-color);
                border: 1px solid var(--msv-accent-brand-color);
                padding: 10px;
                width: 25%;
                margin-left: 20px;
                font-size: $msv-font-size-l;
                line-height: $msv-line-height-l;
            }
        }

        &__requestStatement__modal {
            position: absolute;
        }

        &__requestStatement__fromDate {
            padding-top: 20px;
            width: 300px;
            float: left;
            background-clip: padding-box;
            font-size: $msv-font-size-l;
            line-height: $msv-line-height-l;
        }

        &__requestStatement__toDate {
            padding-top: 20px;
            width: 300px;
            background-clip: padding-box;
            font-size: $msv-font-size-l;
            line-height: $msv-line-height-l;
        }

        @media (min-width: 620px) {
            &__requestStatement__toDate {
                float: right;
            }
        }

        &__dateSelector {
            margin-top: 5px;
            display: block;
            width: 95%;
        }

        &__sendToEmail {
            font-weight: $msv-font-weight-bold;
            padding-top: 5px;
            padding-bottom: 20px;
        }

        &__dropdown {
            width: fit-content;
        }

        &__buttonBar {
            display: flex;
        }
    }
}

.ms-business-organization-list {
    &__action {
        &__bar {
            margin-top: 10px;
        }

        &__requestStatement {
            border: 1px solid var(--msv-accent-brand-color);
            background-color: var(--msv-bg-color);
            padding: 10px;
            width: 100%;
            text-align: center;

            &__toggleDown {
                @include add-icon($msv-ChevronDown, after);
                border: 1px solid var(--msv-accent-brand-color);
                background-color: var(--msv-bg-color);
                text-align: center;
                display: inline;
                min-width: 160px;
                margin-left: 20px;
                margin-top: 20px;
                margin-right: 20px;
                height: fit-content;
            }

            &__toggleUp {
                @include add-icon($msv-ChevronUp, after);
                border: 1px solid var(--msv-accent-brand-color);
                background-color: var(--msv-bg-color);
                text-align: center;
                display: inline;
                min-width: 160px;
                margin-left: 20px;
                margin-top: 20px;
                margin-right: 20px;
                height: fit-content;
            }

            &__dropdownHidden {
                visibility: hidden;
                height: 0;
                display: none;
            }

            &__dropdownVisible {
                background-color: var(--msv-header-bg);
                background-clip: padding-box;
                border: 1px solid $msv-gray-500;
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                word-wrap: break-word;
                z-index: 1060;

                .msc-btn {
                    color: var(--msv-header-font-color);
                    font-weight: var(--msv-font-weight-normal);
                    height: $popover-menu-item-height;
                }

                .msc-arrow {
                    display: block;
                    height: 0.5rem;
                    margin: 0 0.3rem;
                    position: absolute;
                    top: calc((0.5rem + 1px) * -1);
                    width: 1rem;

                    &::before,
                    &::after {
                        border-color: transparent;
                        border-style: solid;
                        border-width: 0 0.5rem 0.5rem 0.5rem;
                        content: '';
                        display: block;
                        position: absolute;
                    }

                    &::before {
                        border-bottom-color: $msv-gray-500;
                        top: 0;
                    }

                    &::after {
                        border-bottom-color: var(--msv-border-color);
                        top: 1px;
                    }
                }
            }

            &__dropdownOption {
                background-color: var(--msv-bg-color);
                display: block;
                width: 100%;
            }

            &__buttonLabel {
                padding-right: 20px;
            }
        }

        &__submitRequest {
            background-color: var(--msv-accent-brand-color);
            color: var(--msv-font-secondary-color);
            border: 1px solid var(--msv-font-secondary-color);
            padding: 10px;
            width: 25%;
            font-size: $msv-font-size-l;
            line-height: $msv-line-height-l;
        }

        &__cancelRequest {
            background-color: var(--msv-font-secondary-color);
            border: 1px solid var(--msv-accent-brand-color);
            padding: 10px;
            width: 25%;
            margin-left: 20px;
            font-size: $msv-font-size-l;
            line-height: $msv-line-height-l;
        }
    }

    &__requestStatement__modal {
        position: absolute;
    }

    &__requestStatement__fromDate {
        padding-top: 20px;
        width: 300px;
        float: left;
        background-clip: padding-box;
        font-size: $msv-font-size-l;
        line-height: $msv-line-height-l;
    }

    &__requestStatement__toDate {
        padding-top: 20px;
        width: 300px;
        background-clip: padding-box;
        font-size: $msv-font-size-l;
        line-height: $msv-line-height-l;
    }

    @media (min-width: 620px) {
        &__requestStatement__toDate {
            float: right;
        }
    }

    &__dateSelector {
        margin-top: 5px;
        display: block;
        width: 95%;
    }

    &__sendToEmail {
        font-weight: bold;
        padding-top: 5px;
        padding-bottom: 20px;
    }

    &__dropdown {
        width: fit-content;
    }

    &__buttonBar {
        display: flex;
    }

    &__selectedUser {
        font-weight: $msv-font-weight-heavy; // semi-bold
        display: block;
    }
}
