$msv-checkout-gift-card-max-flex-basis: 100%;
$msv-checkout-gift-card-padding-bottom: 20px;
$msv-checkout-gift-card-input-label-margin-bottom: 4px;
$msv-checkout-gift-card-button-border-radius: 2px;
$msv-checkout-gift-card-button-margin-left: 0;
$msv-checkout-gift-card-button-margin-top: 20px;
$msv-checkout-gift-card-input-height: 32px;
$msv-checkout-gift-card-input-border-radius: 2px;
$msv-checkout-gift-card-input-padding: 6px 8px;
$msv-checkout-gift-card-input-width: 40%;
$msv-checkout-gift-card-input-pin-exp-text-margin-top: 4px;
$msv-checkout-gift-card-input-pin-exp-text-min-width: 120px;
$msv-checkout-gift-card-input-pin-exp-text-width: 100%;
$msv-checkout-gift-card-input-pin-exp-fields-margin-top: 20px;
$msv-checkout-gift-card-error-icon-margin-right: 8px;
$msv-checkout-gift-card-item-text-width: 60%;
$msv-checkout-gift-card-item-margin-bottom: 8px;
$msv-checkout-gift-card-btn-remove-border: none;
$msv-checkout-gift-card-remove-button-margin-left: 10px;
$msv-checkout-gift-card-input-max-width: 610px;
$msv-checkout-gift-card-input-fields-padding-right: 20px;
$msv-checkout-gift-card-input-fields-min-width: 130px;
$msv-checkout-gift-card-input-fields-width: 25%;
$msv-checkout-gift-card-input-num-fields-width: 50%;
$msv-checkout-gift-card-border-bottom: 1px dashed $msv-divider-color;
$msv-checkout-gift-card-title-margin-bottom: 8px;

//style presets
:root {
	--msv-checkout-gift-card-font-size: var(--msv-body-font-size-l);
	--msv-checkout-gift-card-font-color: var(--msv-font-primary-color);

	// title
	--msv-checkout-gift-card-title-font-size: var(--msv-body-font-size-s);

	// form
	--msv-checkout-gift-card-form-bg: #{$msv-white};
	--msv-checkout-gift-card-form-font-color: var(--msv-font-primary-color);

	// primary button
	--msv-checkout-gift-card-btn-bg: var(--msv-accent-brand-color);
	--msv-checkout-gift-card-btn-font-color: var(--msv-font-secondary-color);
	--msv-checkout-gift-card-btn-border: var(--msv-accent-brand-color);
	--msv-checkout-gift-card-btn-font-size: var(--msv-body-font-size-m);
}

.ms-checkout-gift-card.add {
	border-bottom: $msv-checkout-gift-card-border-bottom;
}

.ms-checkout-gift-card {
	padding-bottom: $msv-checkout-gift-card-padding-bottom;

	&__form {
		flex-flow: row wrap;
	}

	&__title {
		display: block;
		font-size: var(--msv-checkout-gift-card-title-font-size);
		font-weight: var(--msv-font-weight-bold);
		line-height: $msv-line-height-s;
		margin-bottom: $msv-checkout-gift-card-title-margin-bottom;
	}

	&__input-label {
		@include font-content(var(--msv-font-weight-normal), var(--msv-checkout-gift-card-font-size), $msv-line-height-l);
		color: var(--msv-checkout-gift-card-font-color);
		display: block;
		margin-bottom: $msv-checkout-gift-card-input-label-margin-bottom;
		flex-basis: $msv-checkout-gift-card-max-flex-basis;
	}

	&__input-text {
		@include form-input-el(var(--msv-checkout-gift-card-form-bg), var(--msv-checkout-gift-card-form-font-color));
		font-size: var(--msv-checkout-gift-card-font-size);
		width: 100%;
	}

	&__input-fields {
		width: 100%;
	}

	&__input-pin-label,
	&__input-exp-label {
		@include font-content(var(--msv-font-weight-normal), var(--msv-checkout-gift-card-font-size), $msv-line-height-l);
		color: var(--msv-checkout-gift-card-font-color);
		flex-basis: $msv-checkout-gift-card-max-flex-basis;
	}

	&__input-pin-num-text {
		@include form-input-el(var(--msv-checkout-gift-card-form-bg), var(--msv-checkout-gift-card-form-font-color));
		width: 100%;
	}

	&__input-pin-text,
	&__input-exp-text {
		@include form-input-el(var(--msv-checkout-gift-card-form-bg), var(--msv-checkout-gift-card-form-font-color));
		font-size: var(--msv-checkout-gift-card-font-size);
		display: block;
		margin-top: $msv-checkout-gift-card-input-pin-exp-text-margin-top;
		min-width: $msv-checkout-gift-card-input-pin-exp-text-min-width;
		width: $msv-checkout-gift-card-input-pin-exp-text-width;

		&::placeholder {
			color: $msv-gray-300;
		}
	}

	&__input-alert-label {
		@include form-input-alert-message();
		display: block;
	}

	&__input-pin-fields,
	&__input-exp-fields {
		display: inline-block;
		margin-top: $msv-checkout-gift-card-input-pin-exp-fields-margin-top;
		min-width: $msv-checkout-gift-card-input-fields-min-width;
		width: $msv-checkout-gift-card-input-fields-width;
	}

	&__input-pin-fields {
		padding-right: $msv-checkout-gift-card-input-fields-padding-right;
	}

	&__btn-apply {
		@include primary-button(
			var(--msv-checkout-gift-card-btn-bg),
			var(--msv-checkout-gift-card-btn-font-color),
			var(--msv-checkout-gift-card-btn-border)
		);
		font-size: var(--msv-checkout-gift-card-btn-font-size);
		display: block;
		margin-top: $msv-checkout-gift-card-button-margin-top;
		width: 100%;
	}

	&__input-error {
		@include form-input-error-message();
		width: 100%;
		display: block;
	}

	&__item-text {
		font-weight: var(--msv-font-weight-normal);
		font-size: $msv-text-size;
		line-height: $msv-line-height-m;
		width: $msv-checkout-gift-card-item-text-width;
	}

	&__btn-remove {
		@include button-link();
		@include font-content(var(--msv-font-weight-normal), var(--msv-checkout-gift-card-btn-font-size), $msv-line-height-m);
		text-decoration: underline;
		display: flex;
		align-self: flex-end;
		margin-left: auto;

		&.msc-btn {
			display: inline-block;
			margin-left: $msv-checkout-gift-card-remove-button-margin-left;
		}

		@media screen and (max-width: $msv-breakpoint-m) {
			&.msc-btn {
				margin-left: 0;
			}
		}
	}

	@media screen and (min-width: $msv-breakpoint-l) {
		&__input-text {
			width: auto;
			flex-grow: 1;
			max-width: $msv-checkout-gift-card-input-max-width;
		}

		&__input-fields {
			display: inline-block;
			flex-grow: 1;
			width: 100%;

			.ms-checkout-gift-card {
				&__input-num-label {
					display: flex;
				}

				&__input-text {
					width: 100%;
				}
			}
		}

		&__input-num-fields {
			display: inline-block;
			padding-right: $msv-checkout-gift-card-input-fields-padding-right;
			width: $msv-checkout-gift-card-input-num-fields-width;

			.ms-checkout-gift-card__input-label {
				display: block;
			}
		}

		&__btn-apply {
			margin-left: $msv-checkout-gift-card-button-margin-left;
			margin-top: $msv-checkout-gift-card-button-margin-top;
			width: auto;
		}
	}
}
