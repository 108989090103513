$tile-padding-bottom-mobile: 48px;
$tile-details-padding-top: 20px;
$tile-first-margin-left: 0;
$tile-last-margin-right: 0;
$tile-last-padding-bottom-mobile: 0;
$tile-link-margin-left-right: 24px;
$tile-link-padding-top: 10px;

:root {
	// link
	--msv-content-block-link-color: var(--msv-font-primary-color);
	--msv-content-block-link-font-size: var(--msv-body-font-size-m);
}

.ms-content-block {
	&[data-m-layout='tile'] {
		margin: auto;
		width: fit-content;

		.ms-content-block__link {
			display: block;
		}

		.msc-cta__primary {
			@include button-link(var(--msv-content-block-link-color));
			@include font-content(var(--msv-font-weight-normal), var(--msv-content-block-link-font-size), $msv-line-height-m);
		}

		.ms-content-block__details {
			padding-top: $tile-details-padding-top;
		}

		.ms-content-block__details,
		&.textplacement__left .ms-content-block__details {
			text-align: left;

			.ms-content-block__cta {
				padding-top: $tile-link-padding-top;

				a[class^='msc-cta__'] {
					margin-left: $tile-link-margin-left-right;
					text-decoration: underline;

					&:first-child {
						margin-left: $tile-first-margin-left;
					}
				}
			}
		}

		&.textplacement__right .ms-content-block__details {
			text-align: right;

			.ms-content-block__cta {
				/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
				a[class^='msc-cta__'] {
					margin-right: $tile-link-margin-left-right;

					&:last-child {
						margin-right: $tile-last-margin-right;
					}
				}
			}
		}

		&.textplacement__center .ms-content-block__details {
			text-align: center;

			.ms-content-block__cta {
				/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
				a[class^='msc-cta__'] {
					margin-left: $tile-link-margin-left-right / 2;
					margin-right: $tile-link-margin-left-right / 2;

					&:first-child {
						margin-left: $tile-first-margin-left;
					}

					&:last-child {
						margin-right: $tile-last-margin-right;
					}
				}
			}
		}

		@media (max-width: $msv-breakpoint-m) {
			padding-bottom: $tile-padding-bottom-mobile;
			width: 100%;

			& .ms-content-block__image img {
				width: 100%;
			}
		}
	}
}

.col-sm:first-child .ms-content-block[data-m-layout='tile']:first-child,
.col-md:first-child .ms-content-block[data-m-layout='tile']:first-child {
	margin-left: $tile-first-margin-left;
}

.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child,
.col-md:last-child .ms-content-block[data-m-layout='tile']:last-child {
	margin-right: $tile-last-margin-right;
}

@media (max-width: $msv-breakpoint-m) {
	&.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child {
		padding-bottom: $tile-last-padding-bottom-mobile;
	}
}
