.msc-price {
	&.discount-value {
		.msc-price__strikethrough {
			display: block;
			margin-right: 0px;
		}
	}

	&__strikethrough {
		margin-right: 5px;
		text-decoration: line-through;
	}
}
